//@ts-check
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  colors,
  Hidden,
  IconButton,
  Link,
  SvgIcon,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { lazy, Suspense } from "react";
import { Menu as MenuIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Logo from "src/components/Logo";
import { THEMES } from "src/constants";
import { useSelector } from "src/store";
import Account from "./Account";
import Search from "./Search";
import Settings from "./Settings";
// import Help from "./Help";
var configSite = window["configSite"];
var notShowDemoAlert = localStorage.getItem("notShowDemoAlert");

const Help = lazy(() => import("./Help"));
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    background: theme.palette.background.default,
    color: colors.common.white,
    ...(theme.name === THEMES.LIGHT
      ? {
          // boxShadow: '',
          background: theme.palette.background.default,
          color: colors.common.white,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  adminMode: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: colors.common.white,
  },
  logo: {
    maxWidth: "8rem",
    maxHeight: "3rem",
    width: "auto",
  },
  unpaid: {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: colors.common.white,
    minHeight: 64,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const { customerName } = useParams();
  const settings = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const classes = useStyles();
  const { t } = useTranslation();
  let appBarClasses = clsx(classes.root, className);
  const { user, isAuthenticated } = useSelector((state) => state.users);
  const [state, setState] = React.useState({
    notShowDemoAlert: localStorage.getItem("notShowDemoAlert"),
  });

  if (settings.adminMode) {
    appBarClasses = clsx(appBarClasses, classes.adminMode);
  }

  return (
    <AppBar>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>

        <RouterLink
          to={
            currentCustomer && !window.location.hostname.endsWith("arkadu.com")
              ? `/${currentCustomer.shortname}`
              : isAuthenticated
              ? `/app`
              : `/`
          }
        >
          <Logo className={classes.logo} />
        </RouterLink>

        <Box ml={2} flexGrow={1} />

        {/* if host != arkadu */}
        {window.location.hostname.endsWith("arkadu.com") &&
          !["eh.arkadu.com"].includes(window.location.hostname) && (
            <Tooltip title={t("Alcaldía de los Guayos")}>
              <Link
                href="https://alcaldialosguayos.org"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "middle",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <img
                  src="/djmedia/customers/logo/logo_ORVJzuV.jpg"
                  style={{ width: 40, height: 40, borderRadius: 5 }}
                />
                {/* if desktop show text */}
                <Hidden smDown>
                  <Box ml={1} component="span">
                    <Typography sx={{ display: "inline" }}>
                      Alcaldía de los Guayos
                    </Typography>
                  </Box>
                </Hidden>
              </Link>
            </Tooltip>
          )}
        {/* <AndroidApp /> */}
        <Suspense fallback={<CircularProgress />}>
          <Help />
        </Suspense>
        <Search />
        {/* <Contacts /> */}
        {/* <Notifications /> */}

        <Settings />

        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
      {(!state.notShowDemoAlert ||
        Date.now() - state.notShowDemoAlert > 1000 * 60 * 60 * 72) &&
        configSite?.siteDemo && (
          <Toolbar className={classes.unpaid}>
            <Typography variant="h6" color="inherit">
              {t(
                "Está en modo demo, todos los datos se cargan en el servidor de pruebas y serán eliminados en 72 horas"
              )}
            </Typography>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                localStorage.setItem("notShowDemoAlert", Date.now());
                setState({ ...state, notShowDemoAlert: Date.now() });
              }}
            >
              {t("No mostrar durante 72 horas")}
            </Button>
          </Toolbar>
        )}
      {settings.adminMode && customerName === "losguayos2" && (
        <Toolbar className={classes.unpaid}>
          <Typography variant="h4" color="inherit">
            {t(
              "Su cuenta se encuentra insolvente. Por favor, regularice su situación para poder seguir disfrutando de nuestros servicios. Perderá el acceso administrativo a su cuenta en 3 días. Gracias por su comprensión."
            )}
          </Typography>
        </Toolbar>
      )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
