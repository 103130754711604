import { createSlice } from "@reduxjs/toolkit";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  notifications: [],
  newNotification: null,
  currentNotification: null,
  creatingNotification: false,
  updatingNotification: false,
  sentResult: null,
  messagesLoading: false,
  messagesLoaded: false,
  messages: null,
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    getNotifications(state, action) {
      const { notifications } = action.payload;

      state.notifications = notifications;
    },
    newNotification: (state, action) => {
      state.newNotification = action.payload;
      state.creatingNotification = false;
    },
    creatingNotification: (state, action) => {
      state.creatingNotification = action.payload;
    },
    updatingNotification: (state, action) => {
      state.updatingNotification = action.payload;
    },
    sentResult: (state, action) => {
      state.sentResult = action.payload;
    },
    messagesLoading: (state, action) => {
      state.messagesLoading = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
      state.messagesLoading = false;
      state.messagesLoaded = true;
    },
    setCurrentNotification: (state, action) => {
      state.currentNotification = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const getNotifications = () => async (dispatch) => {
  const response = await axios.get("/api/notifications");

  dispatch(slice.actions.getNotifications(response.data));
};

export const addNewNotification = (data) => async (dispatch) => {
  dispatch(slice.actions.creatingNotification(true));
  try {
    const response = await axios.post(arkadu.urls.v2.notify.message.new, data);
    dispatch(slice.actions.newNotification(response.data.data));
  } catch (err) {
    dispatch(slice.actions.creatingNotification(false));
  }
};

export const updateNotification = (data) => async (dispatch) => {
  dispatch(slice.actions.updatingNotification(true));
  try {
    const response = await axios.post(
      arkadu.urls.v2.notify.message.update,
      data
    );
    if (data.state === "sent") {
      dispatch(slice.actions.updatingNotification(false));
      dispatch(slice.actions.newNotification(null));
    }
    dispatch(slice.actions.sentResult(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const clearSentResult = () => async (dispatch) => {
  dispatch(slice.actions.sentResult(null));
};

export const setCurrentNotification = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentNotification(data));
};
export const getUserMessages = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.messagesLoading(true));
    var response = await axios.post(arkadu.urls.v2.notify.message.list, data);
    dispatch(slice.actions.setMessages(response.data.data));
  } catch (err) {
    console.log(err);
  }
};
export default slice;
