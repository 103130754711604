import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setIsLoading, setLoadingMessage } from "src/slices/settings";
import { useDispatch } from "src/store";
import axiosInstance from "src/utils/axios";

const useAsyncRequest = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isAsyncLoading, setAsyncIsLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const [res, setRes] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const api = async (url, data, options, label) => {
    var _res = loadData(url, data, options, label);
    return _res;
  };

  const requestData = async (url, data, options, label, customMessage) => {
    var _res = await loadData(url, data, options, label, customMessage);
    console.log("requestData", _res);
    if (_res.data?.res === 1) {
      setRes({ ..._res.data, status: _res.status });
    } else {
      setRes(_res);
    }
  };

  const loadData = async (url, data, options, label, customMessage) => {
    if (isAsyncLoading) return;
    setAsyncIsLoading(true);

    dispatch(setLoadingMessage(label || t("Obteniendo datos") + "..."));
    dispatch(setIsLoading(true));

    if (label) {
      var sb = enqueueSnackbar(customMessage || t("Obteniendo") + " " + label, {
        variant: "info",
      });
    }
    try {
      var res = await axiosInstance.post(url, data, options);
      if (label) closeSnackbar(sb);
      setAsyncIsLoading(false);
      dispatch(setLoadingMessage(""));
      dispatch(setIsLoading(false));

      if (options && options.responseType === "blob") {
        return res;
      }
      if (res.data.res === 1) {
        if (label) {
          enqueueSnackbar(label + " " + t("cargado exitosamente."), {
            variant: "success",
          });
        }
        return res;
      } else {
        setIsDataError(true);

        enqueueSnackbar(res.data.message || `${label} : error`, {
          variant: "error",
        });
        return res;
      }
    } catch (e) {
      dispatch(setLoadingMessage(""));
      dispatch(setIsLoading(false));
      if (label) closeSnackbar(sb);
      setIsDataError(true);
      console.log(e);
      if(e.status === 403) {
        enqueueSnackbar("No tienes permisos para realizar esta acción", {
          variant: "error",
        });
        setAsyncIsLoading(false);
        return e;
      }
      if (options && options.responseType === "blob") {
        // try to get data from
        var d = e.data;
        if (d instanceof Blob) {
          var reader = new FileReader();
          reader.onload = function (e) {
            var text = reader.result;
            try {
              var json = JSON.parse(text);
              console.log("json", json);
              enqueueSnackbar(json.message || `${label} : error`, {
                variant: "error",
              });
              setAsyncIsLoading(false);
              return json;
            } catch (e) {}
          };
          reader.readAsText(d);
          console.log("blob", d);
        }
        enqueueSnackbar(`${label} : error`, {
          variant: "error",
        });
        setIsDataError(true);
        setAsyncIsLoading(false);
        return e;
      }

      setAsyncIsLoading(false);
      dispatch(setLoadingMessage(""));
      dispatch(setIsLoading(false));
      if (label) closeSnackbar(sb);
      setIsDataError(true);
      enqueueSnackbar(
        (e.data && e.data.message) ||
          e.message ||
          e.statusText ||
          `${label} : error`,
        { variant: "error" }
      );
      return e;
    }
  };

  return {
    res,
    requestData,
    isLoading: isAsyncLoading,
    api,
  };
};

export default useAsyncRequest;
