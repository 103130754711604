import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { server } from "src/coreConfig";
import { setTaxesElement } from "src/slices/taxes";
import { useDispatch, useSelector } from "src/store";
import useAsyncRequest from "./useAsyncRequest";

const urls = {
  taxes: {
    config: `${server}/api/v2/taxes/config/`,
    activity: {
      classifier: `${server}/api/v2/taxes/activity/classifier/`,
      incomes: `${server}/api/v2/taxes/activity/incomes/`,
      index: `${server}/api/v2/taxes/activity/`,
    },
    declaration: {
      attachVat: `${server}/api/v2/taxes/declaration/attachVat/`,
      createAdmin: `${server}/api/v2/taxes/declaration/createAdmin/`,
      createDefinitive: `${server}/api/v2/taxes/declaration/createDefinitive/`,
      getFileTypes: `${server}/api/v2/taxes/declaration/getFileTypes/`,
      addFile: `${server}/api/v2/taxes/declaration/addFile/`,
      update: `${server}/api/v2/taxes/declaration/update/`,
      declaration: `${server}/api/v2/taxes/declaration/`,
      delete: `${server}/api/v2/taxes/declaration/delete/`,
      deleteFee: `${server}/api/v2/taxes/declaration/deleteFee/`,
      deleteFile: `${server}/api/v2/taxes/declaration/deleteFile/`,
      deleteVat: `${server}/api/v2/taxes/declaration/deleteVat/`,
      detail: `${server}/api/v2/taxes/declaration/detail/`,
      downloadVat: `${server}/api/v2/taxes/declaration/downloadVat/`,
      downloadFile: `${server}/api/v2/taxes/declaration/downloadFile/`,
      income: `${server}/api/v2/taxes/declaration/income/`,
      list: `${server}/api/v2/taxes/declaration/list/`,
      state: `${server}/api/v2/taxes/declaration/state/`,
    },
    download_report: `${server}/api/v2/taxes/report/licenseXls/`,
    generateReport: `${server}/api/v2/taxes/report/generate/`,
    deleteReport: `${server}/api/v2/taxes/report/delete/`,
    downloadReport: `${server}/api/v2/taxes/report/download/`,
    reportList: `${server}/api/v2/taxes/report/list/`,
    handle: `${server}/api/v2/taxes/handle/`,
    last_period: `${server}/api/v2/taxes/period/last/`,
    license: {
      add: `${server}/api/v2/taxes/license/add/`,
      addActivity: `${server}/api/v2/taxes/license/addActivity/`,
      addNote: `${server}/api/v2/taxes/license/addNote/`,
      addNoteFile: `${server}/api/v2/taxes/license/addNoteFile/`,
      assign: `${server}/api/v2/taxes/license/assign/`,
      change: `${server}/api/v2/taxes/license/change/`,
      changeActivity: `${server}/api/v2/taxes/license/changeActivity/`,
      changeNote: `${server}/api/v2/taxes/license/changeNote/`,
      detail: `${server}/api/v2/taxes/license/detail/`,
      downloadCertificate: `${server}/api/v2/cert/certificate/license/`,
      downloadList: `${server}/api/v2/taxes/license/downloadList/`,
      downloadNoteFile: `${server}/api/v2/taxes/license/downloadNoteFile/`,
      downloadPending: `${server}/api/v2/taxes/license/download/pending/`,
      feeRequest: `${server}/api/v2/taxes/license/feeRequest/`,
      feeRequestDelete: `${server}/api/v2/taxes/license/feeRequest/delete/`,
      feeRevision: `${server}/api/v2/taxes/license/feeRevision/`,
      feeRevisionDelete: `${server}/api/v2/taxes/license/feeRevision/delete/`,
      list: `${server}/api/v2/taxes/license/list/`,
      missedPeriods: `${server}/api/v2/taxes/license/missedPeriods/`,
      pendingDefinitive: `${server}/api/v2/taxes/license/pendingDefinitive/`,
      resetDeclarations: `${server}/api/v2/taxes/license/resetDeclarations/`,
      revision: `${server}/api/v2/taxes/license/revision/`,
      search: `${server}/api/v2/taxes/license/search/`,
      sync: `${server}/api/v2/taxes/license/sync/`,
      transfer: `${server}/api/v2/taxes/license/transfer/`,
      transferToEmail: `${server}/api/v2/taxes/license/transferToEmail/`,
      exempt: `${server}/api/v2/taxes/license/exempt/`,
      unassign: `${server}/api/v2/taxes/license/unassign/`,
      getProcedures: `${server}/api/v2/taxes/license/getProcedures/`,
      legal: {
        detail: `${server}/api/v2/taxes/license/legal/detail/`,
      },
      closed: {
        detail: `${server}/api/v2/taxes/license/closed/detail/`,
      },
    },
    penalty: {
      add: `${server}/api/v2/taxes/penalty/add/`,
      attach: `${server}/api/v2/taxes/penalty/attach/`,
      change: `${server}/api/v2/taxes/penalty/change/`,
      delete: `${server}/api/v2/taxes/penalty/delete/`,
      detail: `${server}/api/v2/taxes/penalty/detail/`,
      downloadAttach: `${server}/api/v2/taxes/penalty/downloadAttach/`,
      list: `${server}/api/v2/taxes/penalty/list/`,
    },
    period: {
      activities: `${server}/api/v2/taxes/period/activities/`,
      addFee: `${server}/api/v2/taxes/period/addFee/`,
      declarations: `${server}/api/v2/taxes/period/declarations/`,
      deleteFee: `${server}/api/v2/taxes/period/deleteFee/`,
      main: `${server}/api/v2/taxes/period/`,
      pendingLicenses: `${server}/api/v2/taxes/period/`,
      stats: `${server}/api/v2/taxes/stats/`,
    },
    retention: {
      activities: `${server}/api/v2/taxes/retention/activities/`,
      add: `${server}/api/v2/taxes/retention/add/`,
      add_statement: `${server}/api/v2/taxes/retention/statement/new/`,
      change_state: `${server}/api/v2/taxes/retention/statement/changeState/`,
      delete: `${server}/api/v2/taxes/retention/delete/`,
      loadCsv: `${server}/api/v2/taxes/retention/statement/loadCsv/`,
      statement_detail: `${server}/api/v2/taxes/retention/statement/detail/`,
      statement_list: `${server}/api/v2/taxes/retention/statement/`,
      deleteFee: `${server}/api/v2/taxes/retention/statement/deleteFee/`,
    },
  },
};

const useTaxes = () => {
  const { currentCustomer } = useSelector((state) => state.customers);
  const { currentLicense } = useSelector((state) => state.taxes);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.settings);
  const { api } = useAsyncRequest();
  const { t } = useTranslation();

  const getLicenseDetail = async (id) => {
    try {
      const response = await api(urls.taxes.license.detail, {
        id: id,
        customer_id: currentCustomer.id,
        mode: admin ? "admin" : "user",
        action: "retrieve",
      }, {}, t("Detalle de licencia"));


      if (response.data.res === 1) {
        dispatch(setTaxesElement({ currentLicense: response.data.data }));
      }
    } catch (error) {

      console.log(error);

      return null;
    }
  };

  const getCurrentLicenseProcedures = async () => {
    try {
      const response = await api(urls.taxes.license.getProcedures, {
        id: currentLicense.id,
        customer_id: currentCustomer.id,
        mode: admin ? "admin" : "user",
      }, {}, t("Procedimientos de licencia"));
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            currentLicenseProcedures: response.data.data,
          })
        );
      }
    } catch (error) {
      return null;
    }
  }

  const getTaxesConfig = async () => {
    try {
      const response = await api(
        urls.taxes.config,
        {
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
        },
        {},
        t("Configuración del módulo de hacienda")
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            currentCustomerClassifier: response.data.data.activities,
            liquorActivities: response.data.data.liquorActivities,
            licenseProcedures: response.data.data.procedures,
            licenseCategories: response.data.data.licenseCategories,
          })
        );
      }
    } catch (error) {
      return null;
    }
  };

  const createLicense = async (data) => {
    try {
      const response = await api(
        urls.taxes.license.add,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
        },
        {},
        t("Creando licencia")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  return {
    getLicenseDetail,
    getTaxesConfig,
    getCurrentLicenseProcedures,
    createLicense,
  };
};

export default useTaxes;
